<template>
  <div class="module-box">
    <div class="module-title">基础服务</div>
    <div class="grid-box">
      <router-link :to="item.url" class="grid-item" v-for="(item,index) in list" :key="index">
        <div class="icon">
          <img :src="item.photo" class="contain-img" alt="">
        </div>
        <span>{{ item.title }}</span>
      </router-link>
    </div>
  </div>
</template>

<script>
  
  export default {
    props: {
      list: {}
    },
    data() {
      return {

      }
    }
  }
</script>

<style lang="less" scoped>
  @import "../style.less";
</style>